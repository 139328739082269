import { getEverythingForAdmin } from 'API/reporting'
import { importmodule } from 'API/reporting'
import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { CourseType } from 'types/CourseAdditionMetaData'
import AddClassesTable from '../../../components/admin/AddClassesTable'
import { exportToCsv } from '../course/[id]'
import { Icon } from '@iconify/react'
import Flatpickr from 'react-flatpickr'
import { Form } from 'react-bootstrap'

export default function Reporting_teacher() {
	const [tableData, setTableData] = useState<Array<CourseType>>([])
	const [offset, setOffset] = React.useState(0)
	const [loading, setLoading] = React.useState(false)
	const [selectedUsers, setSelectedUsers] = useState([])
	const [state, setState] = useState({ available_from: 0, available_till: 0 })
	const questionDates = [
		{ text: 'Activities From', name: 'available_from' },
		{ text: 'Activities Till', name: 'available_till' },
	]
	const [modalData, setModalData] = useState({
		year: 'All',
		Institute: 'All',
		status: 'All',
		Program: 'All',
		include_teachers: true,
	})
	const columns = [
		{
			text: 'Institute',
			dataField: 'Institute',
			sort: true,
		},

		{
			text: 'Year',
			dataField: 'year',
			sort: true,
		},
		{
			text: 'Description',
			dataField: 'description',
			sort: true,
		},
		{
			text: 'Subject',
			dataField: 'name',
			sort: true,
		},
		{
			text: 'Username',
			dataField: 'username',
			sort: true,
		},
		{
			text: 'First Name',
			dataField: 'first_name',
			sort: true,
		},
		{
			text: 'Last Name',
			dataField: 'last_name',
			sort: true,
		},
		{
			text: 'Email',
			dataField: 'email',
			sort: true,
		},
		{
			text: 'Quiz',
			dataField: 'quiz',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},
		{
			text: 'Assignment',
			dataField: 'assignment',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},
		{
			text: 'Resource',
			dataField: 'resource',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},
		{
			text: 'Lecture',
			dataField: 'lecture',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},
		{
			text: 'External Link',
			dataField: 'external_link',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},
		{
			text: 'External Video',
			dataField: 'external_video',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},
		{
			text: 'Forum',
			dataField: 'forum',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},
		{
			text: 'CBME Appraisal',
			dataField: 'feedback',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},
	]

	useEffect(() => {
		// const run = async () => {
		// 	const data = await getEverythingforAdmin()
		// }
		// run()
	}, [])

	const getActivitiesDetails = async () => {
		console.log(state)
		const limit = 1
		const data = await getEverythingForAdmin({
			available_from: state.available_from,
			available_till: state.available_till,
			Institute: modalData.Institute,
			year: modalData.year,
			status: modalData.status,
			Program: modalData.Program,
			limit: limit,
			offset: offset,
		})
		if (data) {
			setOffset(offset + limit)
			setTableData(data)
		}
	}
	const handleUpdate = (e: { target: { value: string | number; name: string } }, type: 'text' | 'datetime-local' = 'text') => {
		const value = e.target.value
		if (type === 'datetime-local' && e.target.name === 'available_from') {
			const value1 = new Date(value).getTime()
			state.available_from = value1
		} else if (type === 'datetime-local' && e.target.name === 'available_till') {
			const value1 = new Date(value).getTime()
			state.available_till = value1
		}
	}

	const downloadData = async () => {
		try {
			if (tableData) {
				const headers = [
					'Institute',
					'year',
					'description',
					'name',
					'username',
					'first_name',
					'last_name',
					'email',
					'quiz',
					'assignment',
					'resource',
					'lecture',
					'external_link',
					'external_video',
					'forum',
					'feedback',
				]
				const headers2 = [
					'Institute',
					'Year',
					'Description',
					'Subject',
					'Username',
					'First Name',
					'Last Name',
					'Email',
					'No. of Quiz',
					'No. of Assignment',
					'No. of Resource',
					'No. of Lecture',
					'No. of External Link',
					'No. of External Video',
					'No. of Forum',
					'No. of Feedback',
				]
				const csv_value = tableData.map((elem) => {
					const row: any = []
					headers.forEach((header) => {
						row.push((elem as any)?.[header] ?? undefined)
					})
					return row
				})
				const arr = [headers2, ...csv_value]
				console.log(arr)
				exportToCsv(`Course_Report_${new Date().toLocaleDateString()}`, arr)
			}
		} catch (err) {}
	}
	return (
		<Fragment>
			<div className='col-3'>
				<h2>Teacher Reports</h2>
			</div>
			<Form.Group>
				<div className='row'>
					{questionDates.map((elem) => {
						return (
							<>
								<div className='col-6'>
									<Form.Label>{elem.text}</Form.Label>
									<>
										{/* Date Picker */}
										<Flatpickr
											// selected={Date.now()}

											options={{
												altInput: true,
												enableTime: true,
												time_24hr: true,
												altFormat: 'F j, Y H:i',
												dateFormat: 'Y-m-d H:i',
											}}
											onChange={(e) =>
												handleUpdate(
													{
														target: { value: e[0].getTime(), name: elem.name },
													},
													'datetime-local'
												)
											}
											name={elem.name}
										/>
									</>
								</div>
							</>
						)
					})}
				</div>
			</Form.Group>
			<div className='row'>
				<div className='col-6'>
					<Form.Group controlId='txtCategory'>
						<Form.Label>Institute</Form.Label>
						<select
							name='courseType'
							id='selectList'
							className='form-control'
							onChange={(e) => {
								setModalData((modalData) => ({
									...modalData,
									Institute: e.target.value,
								}))
								// handleUpdate({})
							}}
						>
							<option value='All'>All</option>
							<option value='JNMC'>JNMC</option>
							<option value='SPDC'>SPDC</option>
							<option value='MGAC'>MGAC</option>
							<option value='RNPC'>RNPC</option>
							<option value='DMCP'>DMCP</option>
							<option value='DMMC'>DMMC</option>
							<option value='SRMMCON'>SRMMCON</option>
							<option value='FEAT'>FEAT</option>
							<option value='CDOE'>CDOE</option>
							<option value='AHS'>AHS</option>
							<option value='FOCM'>FOCM</option>
							<option value='Others'>Others</option>
						</select>
					</Form.Group>
				</div>
				<div className='col-6'>
					<Form.Group controlId='txtCategory'>
						<Form.Label>Year</Form.Label>
						<select
							name='courseType'
							id='selectList'
							className='form-control'
							onChange={(e) => {
								setModalData((modalData) => ({
									...modalData,
									year: e.target.value,
								}))
							}}
						>
							<option value='All'>All</option>
							<option value='I'>I</option>
							<option value='II'>II</option>
							<option value='III'>III</option>
							<option value='IV'>IV</option>
						</select>
					</Form.Group>
				</div>
				<div className='col-6'>
					<Form.Group controlId='txtCategory'>
						<Form.Label>status</Form.Label>
						<select
							name='courseType'
							id='selectList'
							className='form-control'
							onChange={(e) => {
								setModalData((modalData) => ({
									...modalData,
									status: e.target.value,
								}))
							}}
						>
							<option value='All'>All</option>
							<option value='Archived'>Archived</option>
							<option value='Active'>Active</option>
						</select>
					</Form.Group>
				</div>
				<div className='col-6'>
					<Form.Group controlId='txtCategory'>
						<Form.Label>Program</Form.Label>
						<select
							name='courseType'
							id='selectList'
							className='form-control'
							onChange={(e) => {
								setModalData((modalData) => ({
									...modalData,
									Program: e.target.value,
								}))
							}}
						>
							<option value='All'>All</option>
							<option value='PG'>PG</option>
							<option value='UG'>UG</option>
						</select>
					</Form.Group>
				</div>
			</div>

			<div className='float-left'>
				<button onClick={getActivitiesDetails} className='btn btn-primary'>
					<Icon icon='bx:bx-download' className='fa fa-download px-2 '></Icon>
					Get Activity Count
				</button>
				<button
					onClick={async () => {
						const limit = 1
						if (tableData) {
							setOffset(offset + limit)
							setLoading(true)
							const data = await getEverythingForAdmin({
								available_from: state.available_from,
								available_till: state.available_till,
								Institute: modalData.Institute,
								year: modalData.year,
								status: modalData.status,
								Program: modalData.Program,
								limit: limit,
								offset: offset,
							})

							const arr: any = tableData
							arr.push(...data)

							setTableData(arr)

							console.log(tableData)
							setLoading(false)
						}
					}}
					className='btn btn-primary'
				>
					<Icon icon='bx:bx-download' className='fa fa-download px-2 '></Icon>
					{loading ? 'Loading...' : 'Load More'}
				</button>
			</div>
			<div className='float-right'>
				<button onClick={downloadData} className='btn btn-primary'>
					<Icon icon='bx:bx-download' className='fa fa-download px-2 '></Icon>
					Download Report
				</button>
			</div>
			<AddClassesTable data={tableData} columns={columns} />
		</Fragment>
	)
}
